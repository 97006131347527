import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import WalletApiService from "@/core/services/api.service.wallet";
import VuetifyPdf from "vuetify-pdfjs/src/App.vue";

export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },
    name: "invoice-viewer",
    data() {
        return {
            invoiceUrl: null,
            redeemId: null,
            shopId: null,
            loading: false,
            valid: false,
            isSaving: false,
            shopRedeems: [],
            shopsSearchInput: null,
            reason: null,
            requiredRule: [v => !!v || "Field is required"]
        };
    },
    components: {
        VuetifyPdf
    },
    async created() {
        this.invoiceUrl = this.$route.params.invoiceUrl;
        this.redeemId = this.$route.params.redeemId;
        this.$log.debug("redeemId: ", this.redeemId);
        this.$log.debug("Invoice url: ", this.invoiceUrl);
        this.$forceUpdate();
        this.invoiceUrl = "https://cdn.ooblee.me/images/94b123e7-e444-4dc9-bd04-aa00884f3a41.pdf"
        this.$store.dispatch(SET_BREADCRUMB, [{title: "Invoice"}]);
        await WalletApiService.init();
    },
    methods: {
        previousState() {
            this.$router.go(-1);
        },
        approveInvoice() {
            let params = {};
            params["redeemId"] = this.redeemId;
            params["reason"] = this.reason;
            this.loading = true;
            WalletApiService.post("/CompleteRedeem", params)
                .then(response => {
                    // JSON responses are automatically parsed.
                    this.$log.debug("Approve redeem: ", response.data);
                    this.shopRedeems = response.data;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.previousState()
                });

        },
        rejectInvoice() {
            let params = {};
            params["redeemId"] = this.redeemId;
            params["reason"] = this.reason;
            this.loading = true;
            WalletApiService.post("/RejectRedeem", params)
                .then(response => {
                    // JSON responses are automatically parsed.
                    this.$log.debug("Approve redeem: ", response.data);
                    this.shopRedeems = response.data;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.previousState()
                });
        }
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        headers() {
            return [
                {text: "Id", align: "start", value: "id", sortable: false},
                {text: this.$i18n.t('commonTable.shopId'), value: "shopId", sortable: false},
                {text: this.$i18n.t('commonTable.shopName'), value: "shopName", sortable: false},
                {text: this.$i18n.t('common.created'), value: "created", sortable: false},
                {text: this.$i18n.t('commonTable.status'), value: "redeemStatus", sortable: false},
                {text: this.$i18n.t('pages.membership.invoice'), value: "invoiceUrl", sortable: false}
            ]
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        }
    }
};
